import React from "react";
import "./DownloadApp.scss";
import { Link } from "react-router-dom";

function DownloadApp() {
  return (
    <div className="px-5 my-10">
      {/* <p className="heading2">Get The Sydney OzTag App Today!</p> */}
      <p className="heading5">
        Stay on top of all fixtures, scores, venue changes and more!
      </p>
      <div className=" flex justify-center items-center">
        <Link to={"https://apps.apple.com/us/app/sydney-oztag/id6476576042"} target="_blank"> 
          <img
            src="./apple-app-store-travel-awards-globestamp-7 2.svg"
            alt=""
            className="pointer w-[170px] md:w-[200px]"
          />
        </Link>
        <img
          src="./apple-app-store-travel-awards-globestamp-7 3.svg"
          alt=""
          className="pointer w-[170px] md:w-[200px]"
        />
      </div>
    </div>
  );
}

export default DownloadApp;
