import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import api from "../api";
import { useCookies } from "react-cookie";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminEditPlayerData from "./adminPanel/AdminEditPlayerData";
import ConfirmRemovePlayer from "./adminPanel/ConfirmRemovePlayer";
import ReactLoading from "react-loading";


function AdminPlayers({ userId }) {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [modalType, setModalType] = useState("add");
  const [cookies] = useCookies(["token"]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [visible, setVisible] = useState(false);

  const getAllPlayers = async () => {
    const response = await api.get("auth/api/v1/getAllPlayers", {
      headers: { token: cookies.token },
    });
    // console.log(response.data ,'===getAllPlayers==<');
    setPlayers(response.data);
  };

  useEffect(() => {
    getAllPlayers();
  }, []);

  const checkSubs =(subs)=>{
    if(subs.length>0){
      let paid = true
      subs.forEach((sub)=>{
        if(!sub.paidStatus){
          paid=false
        }
      })
      return paid
    }
    return false
  }

  let columns = [
    {
      dataField: "_id",
      text: "Player ID",
      style: { fontSize: "small", width: "210px" },
      hidden: true,
    },
    {
      dataField: "playerCode",
      text: "Player Code",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "104px", textAlign: "center" };
      },
    },
    {
      dataField: "image",
      text: "Image",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "56px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        {
          return row?.image ? (
            <img
              loading="lazy"
              className="w-10 h-10 rounded-full"
              src={`${process.env.REACT_APP_SERVER_URL}` + row?.image}
              alt="Rounded avatar"
            ></img>
          ) : (
            <div className="relative w-18 h-18 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg
                className="absolute w-20 h-20 text-gray-400 -left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          );
        }
      },
    },
    {
      dataField: "firstName",
      text: "First Name",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "96px", textAlign: "center" };
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "92px", textAlign: "center" };
      },
    },
    {
      dataField: "subscriptions?.team?.teamName",
      text: "Team Name",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <span
            className={`d-inline-block ${
              row?.subscriptions.length === 0 && "bg-red-400 font-bold"
            }`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.subscriptions
              ?.map(
                (subscription) =>
                  `${subscription?.team?.teamName} is ${subscription?.team?.status}`
              )
              .join(" - ")}
          >
            {row?.subscriptions.length > 0
              ? row?.subscriptions[0].team?.teamName
              : "No team"}
            {row?.subscriptions.length > 1 && " ...etc"}
          </span>
        );
      },
      filterValue: (cell, row) => {
        let searchVal = "";
        row?.subscriptions?.forEach((subscription) => {
          searchVal = searchVal + subscription?.team?.teamName;
        });
        return searchVal;
      },
    },
    {
      dataField: "email",
      text: "Email",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "createdAt",
      text: "Register on",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return <div>{new Date(row.createdAt).toLocaleDateString()}</div>;
      },
    },
    {
      dataField: "regPlatform",
      text: "Platform",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
    {
      dataField: "paid",
      text: "Status",
      style: { fontSize: "small" },
      headerStyle: (colum, colIndex) => {
        return { width: "88px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <div className="text-center flex">
            {checkSubs(row.subscriptions) ? (
              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                Active
              </span>
            ) : (
              <>
                <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                  Pending
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      text: "Actions",
      // style: { fontSize: "small" },

      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <>
            <div className="flex justify-center gap-3 items-start mt-2">
              <FiEdit
                className="cursor-pointer hover:text-green-500 text-xl "
                onClick={() => handleEditPlayer(row)}
              />
              <TiDeleteOutline
                className="cursor-pointer hover:text-red-500 text-xl"
                onClick={() => handleRemoveBtn(row._id)}
              />
              {/* {userId === 4 && (
                <>
                  {!row.paid ? (
                    <button
                      className="text-center underline text-blue-600 hover:no-underline cursor-pointer "
                      onClick={() => handleActivation(row)}
                    >
                      Activate
                    </button>
                  ) : (
                    <button
                      onClick={() => handleActivation(row)}
                      className="text-center underline text-yellow-800 hover:no-underline cursor-pointer"
                    >
                      Pending
                    </button>
                  )}
                </>
              )} */}
            </div>
          </>
        );
      },
    },
  ];
  const handleAddPlayer = () => {
    setModalType("add");
    setVisible(true);
    setSelectedPlayer();
  };

  const handleEditPlayer = (data) => {
    // console.log(data);
    setModalType("edit");
    setVisible(true);
    setSelectedPlayer(data);
  };
  const handleRemoveBtn = (teamId) => {
    // console.log(teamId)
    setSelectedPlayer(teamId);
    setDeleteModal(true);
  };
  // const onSelectHandler = (row, isSelect, rowIndex, e) => {
  //   if (isSelect) {
  //     setSelectedRows((prev) => [...prev, row._id]);
  //     return;
  //   }
  //   setSelectedRows((prev) => prev.filter((id) => id !== row._id));
  // };

  // const onSelectAllHandler = (isSelect, rows, e) => {
  //   if (isSelect) {
  //     setSelectedRows(rows.map((row) => row._id));
  //     return;
  //   }
  //   setSelectedRows([]);
  // };

  // const handleActivation = async (player) => {
  //   const row = { ...player, paid: !player.paid };
  //   const modify = await api.patch(
  //     "/auth/api/v1/updatePlayerDataFromTeam",
  //     row,
  //     {
  //       headers: { token: cookies.token },
  //     }
  //   );
  //   getAllPlayers();
  // };
  // const handleAllActivation = async () => {
  //   await api.put(
  //     "/auth/api/v1/updateStatusPlayers",
  //     { ids: selectedRows, status: true },
  //     {
  //       headers: { token: cookies.token },
  //     }
  //   );
  //   getAllPlayers();
  // };
  // This is my custom search component
  const MySearch = (props) => {
    let input;
    const handleOnCahngeSearch = () => {
      if (input.value.toLowerCase() === "active") {
        props.onSearch(`${true}`);
        return;
      } else if (input.value.toLowerCase() === "pending") {
        props.onSearch(`${false}`);
        return;
      }
      props.onSearch(input.value);
    };
    return (
      <div>
        <input
          className="form-control"
          ref={(n) => (input = n)}
          type="text"
          onChange={handleOnCahngeSearch}
        />
      </div>
    );
  };

  return (
    <>
      <ConfirmRemovePlayer
        confirmRemove={deleteModal}
        setConfirmRemove={setDeleteModal}
        playerId={selectedPlayer}
        getAllPlayers={getAllPlayers}
      />

      <AdminEditPlayerData
        show={visible}
        setShow={setVisible}
        type={modalType}
        getAllPlayers={getAllPlayers}
        selectedPlayer={selectedPlayer}
      />
      {/* <AdminAddNewPlayerModal show={visible} setShow={setVisible} /> */}
      {players.length > 0 ? (
        <div className="flex flex-col w-4/5 scale-90 -mx-20 min-h-screen">
          <ToolkitProvider
            keyField="_id"
            data={players}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="flex w-full justify-between">
                  <MySearch
                    {...props.searchProps}
                    placeholder="Search"
                    className="input "
                  />
                  <div className="flex gap-2">
                    {/* {selectedRows.length > 0 && (
                      <button
                        className="normalButton py-2 text-sm"
                        onClick={() => handleAllActivation()}
                      >
                        Active All
                      </button>
                    )} */}
                    <button
                      className="normalButton py-2 text-sm"
                      onClick={() => handleAddPlayer()}
                    >
                      Add Player
                    </button>
                  </div>
                </div>
                <hr />

                <BootstrapTable
                  classes="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  // selectRow={{
                  //   mode: "checkbox",
                  //   onSelect: onSelectHandler,
                  //   onSelectAll: onSelectAllHandler,
                  // }}
                  hover={true}
                  striped
                  bordered={true}
                  loading={true}
                  condensed
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <div className="w-4/5 flex justify-center items-center">
          <ReactLoading type="spin" color="#00B5FF" width={30} height={30} />
        </div>
      )}
    </>
  );
}

export default AdminPlayers;
